exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-1-js": () => import("./../../../src/pages/about-1.js" /* webpackChunkName: "component---src-pages-about-1-js" */),
  "component---src-pages-academic-quality-assurance-js": () => import("./../../../src/pages/academic-quality-assurance.js" /* webpackChunkName: "component---src-pages-academic-quality-assurance-js" */),
  "component---src-pages-acceptance-plan-js": () => import("./../../../src/pages/acceptance-plan.js" /* webpackChunkName: "component---src-pages-acceptance-plan-js" */),
  "component---src-pages-administrative-assistant-js": () => import("./../../../src/pages/administrativeAssistant.js" /* webpackChunkName: "component---src-pages-administrative-assistant-js" */),
  "component---src-pages-centerfor-continuing-education-js": () => import("./../../../src/pages/centerfor-continuing-education.js" /* webpackChunkName: "component---src-pages-centerfor-continuing-education-js" */),
  "component---src-pages-citizens-affairs-js": () => import("./../../../src/pages/citizens-affairs.js" /* webpackChunkName: "component---src-pages-citizens-affairs-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-facts-and-statistics-js": () => import("./../../../src/pages/facts-and-statistics.js" /* webpackChunkName: "component---src-pages-facts-and-statistics-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-graduate-students-js": () => import("./../../../src/pages/graduate-students.js" /* webpackChunkName: "component---src-pages-graduate-students-js" */),
  "component---src-pages-handling-of-hazardous-and-toxic-js": () => import("./../../../src/pages/handling-of-hazardous-and-toxic.js" /* webpackChunkName: "component---src-pages-handling-of-hazardous-and-toxic-js" */),
  "component---src-pages-ietls-central-js": () => import("./../../../src/pages/ietls-central.js" /* webpackChunkName: "component---src-pages-ietls-central-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instructions-and-controls-js": () => import("./../../../src/pages/instructions-and-controls.js" /* webpackChunkName: "component---src-pages-instructions-and-controls-js" */),
  "component---src-pages-it-center-js": () => import("./../../../src/pages/it-center.js" /* webpackChunkName: "component---src-pages-it-center-js" */),
  "component---src-pages-latest-news-js": () => import("./../../../src/pages/latestNews.js" /* webpackChunkName: "component---src-pages-latest-news-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-psychological-and-educational-guidance-js": () => import("./../../../src/pages/psychological-and-educational-guidance.js" /* webpackChunkName: "component---src-pages-psychological-and-educational-guidance-js" */),
  "component---src-pages-public-relations-and-media-js": () => import("./../../../src/pages/public-relations-and-media.js" /* webpackChunkName: "component---src-pages-public-relations-and-media-js" */),
  "component---src-pages-qualification-and-employment-js": () => import("./../../../src/pages/qualification-and-employment.js" /* webpackChunkName: "component---src-pages-qualification-and-employment-js" */),
  "component---src-pages-scientific-affairs-js": () => import("./../../../src/pages/scientific-affairs.js" /* webpackChunkName: "component---src-pages-scientific-affairs-js" */),
  "component---src-pages-scientific-assistant-js": () => import("./../../../src/pages/scientificAssistant.js" /* webpackChunkName: "component---src-pages-scientific-assistant-js" */),
  "component---src-pages-scientific-promotions-affairs-js": () => import("./../../../src/pages/scientific-promotions-affairs.js" /* webpackChunkName: "component---src-pages-scientific-promotions-affairs-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-site-map-js": () => import("./../../../src/pages/site-map.js" /* webpackChunkName: "component---src-pages-site-map-js" */),
  "component---src-pages-student-affairs-and-registration-js": () => import("./../../../src/pages/student-affairs-and-registration.js" /* webpackChunkName: "component---src-pages-student-affairs-and-registration-js" */),
  "component---src-pages-students-activities-js": () => import("./../../../src/pages/students-activities.js" /* webpackChunkName: "component---src-pages-students-activities-js" */),
  "component---src-pages-studies-and-planning-js": () => import("./../../../src/pages/studies-and-planning.js" /* webpackChunkName: "component---src-pages-studies-and-planning-js" */),
  "component---src-pages-teaching-staff-js": () => import("./../../../src/pages/teaching-staff.js" /* webpackChunkName: "component---src-pages-teaching-staff-js" */),
  "component---src-pages-top-students-js": () => import("./../../../src/pages/top-students.js" /* webpackChunkName: "component---src-pages-top-students-js" */),
  "component---src-pages-university-college-js": () => import("./../../../src/pages/university-college.js" /* webpackChunkName: "component---src-pages-university-college-js" */),
  "component---src-pages-university-image-js": () => import("./../../../src/pages/university-image.js" /* webpackChunkName: "component---src-pages-university-image-js" */),
  "component---src-pages-university-president-js": () => import("./../../../src/pages/universityPresident.js" /* webpackChunkName: "component---src-pages-university-president-js" */),
  "component---src-pages-women-empowerment-js": () => import("./../../../src/pages/women-empowerment.js" /* webpackChunkName: "component---src-pages-women-empowerment-js" */),
  "component---src-templates-activitiesuniversity-template-js": () => import("./../../../src/templates/activitiesuniversity-template.js" /* webpackChunkName: "component---src-templates-activitiesuniversity-template-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-centerfor-continuing-education-news-template-js": () => import("./../../../src/templates/centerforContinuingEducationNews-template.js" /* webpackChunkName: "component---src-templates-centerfor-continuing-education-news-template-js" */),
  "component---src-templates-newsuniversity-template-js": () => import("./../../../src/templates/newsuniversity-template.js" /* webpackChunkName: "component---src-templates-newsuniversity-template-js" */),
  "component---src-templates-patents-template-js": () => import("./../../../src/templates/patents-template.js" /* webpackChunkName: "component---src-templates-patents-template-js" */),
  "component---src-templates-project-template-js": () => import("./../../../src/templates/project-template.js" /* webpackChunkName: "component---src-templates-project-template-js" */),
  "component---src-templates-research-template-js": () => import("./../../../src/templates/research-template.js" /* webpackChunkName: "component---src-templates-research-template-js" */),
  "component---src-templates-scientific-magazines-template-js": () => import("./../../../src/templates/scientificMagazines-template.js" /* webpackChunkName: "component---src-templates-scientific-magazines-template-js" */),
  "component---src-templates-student-activities-news-template-js": () => import("./../../../src/templates/studentActivitiesNews-template.js" /* webpackChunkName: "component---src-templates-student-activities-news-template-js" */),
  "component---src-templates-women-empowerment-news-template-js": () => import("./../../../src/templates/womenEmpowermentNews-template.js" /* webpackChunkName: "component---src-templates-women-empowerment-news-template-js" */)
}

